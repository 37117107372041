body {
    font-family: 'Nunito';
    letter-spacing: 0.0625em;
    /* background-color: rgba(0,0,0,.02); */
}

a {
    /* color: #1c7b65; */
    color: black;
    
}

a:hover {
  text-decoration: none;
  color: #1c7b65;
  font-weight: 500; 
}

.zoom {
  transition: transform .2s; /*Animation*/
}

.zoom:hover {
  transform: scale(1.05);
  /* background-color: rgba(56, 3, 83, 0.00);
  box-shadow: 0px 2px 5px rgba(62, 17, 85, 0.4); */
}

.custom-contact-zoom {
  transition: transform .4s; /*Animation*/
}

.custom-contact-zoom:hover {
  /* color: hotpink; */
  /* box-shadow: 0px 0px 5px gray; */
  color: #1c7b65;
  transform: scale(1.02);
  /* background-color: #1c7b65; */
}

/* PORTFOLIO SECTION */
.portfolio-section {
    padding: 10rem 0;
  }
  
  .portfolio-section .featured-text {
    padding: 2rem;
  }
  
  @media (min-width: 992px) {
    .portfolio-section .featured-text {
      padding: 0 0 0 2rem;
      border-left: 0.5rem solid #64a19d;
    }
  }
  
  .portfolio-section .portfolio-text {
    padding: 3rem;
    font-size: 90%;
  }
  
  @media (min-width: 992px) {
    .portfolio-section .portfolio-text {
      padding: 5rem;
    }
    .portfolio-section .portfolio-text hr {
      border-color: #64a19d;
      border-width: .25rem;
      width: 30%;
    }
  }

/* DELTA CUSTOM */

.custom-section-container {
  margin-top: 15%;
  padding-top: 75px;
  margin-bottom: 7%;
}

.custom-careers-section-container {
  /* margin-top: 10%; */
  padding-top: 100px;
}


.custom-section-header {
  color: rgba(0,0,0,.6);
}

.custom-scroller-div {
  text-align: center;
}

/* OPPORTUNITY EVALUATION */

.custom-opportunity-section-container {
  /* margin-top: 10%; */
  padding-top: 100px;
}

.custom-opportunity-section-container h5 {
  font-size: 1em;
  font-weight: 700;
  color: rgba(0,0,0, .4);
  /* letter-spacing: 1px; */
}

.custom-opportunity-section-container p {
  font-size: .8em;
  font-weight: 10000;
  color: rgba(0,0,0, .8)
}

.custom-opportunity-section-container li {
  font-size: .8em;
  font-weight: 500;
  color: rgba(0,0,0, .8)
}

.project-card-custom {
  height: 220px;
  padding: 20px;
  border-style: solid;
  border-width: thin;
  border-radius: 3px;
  /* background-color: rgba(56, 3, 83, 0.04); */
  /* background-color: rgba(56, 3, 83, 0.04); */
  /* background-color: rgba(0, 0, 0, 0.01); */
  border-color: rgba(0, 0, 0, 0.1);
  /* margin-bottom: 5px;
  margin-right: 2px;
  margin-left: 2px; */
  letter-spacing: -.3px;
  margin-bottom: 10px;
}

.custom-dropdown-btn {
  background-color: white;
  color: black;
  margin: 5px;
}

.custom-dropdown-btn:hover {
  background-color: black;
}

/* p {
  margin-top: 0;
  margin-bottom: 0rem;
} */

.custom-page-title {
  color: rgba(0,0,0,.5);
  font-size: 1.7em;
  margin-top: 50px;
}